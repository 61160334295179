import { BrowserMultiFormatReader, NotFoundException } from '@zxing/library';
const codeReader = new BrowserMultiFormatReader();

export function start(callback){
    codeReader.timeBetweenScansMillis = 2000
    let selectedDeviceId;
    codeReader.listVideoInputDevices()
        .then((videoInputDevices) => {
            let backCameraDevices = videoInputDevices.filter(device => /back|rear|environment/gi.test(device.label))
            let lastDevice = backCameraDevices.length - 1
            selectedDeviceId = lastDevice >= 0 ? backCameraDevices[lastDevice].deviceId : undefined

            codeReader.decodeFromVideoDevice(selectedDeviceId, 'video', (result, err) => {
                if (result) {
                    console.log(result)
                    callback({
                        codeResult: {
                            code: result.text
                        }
                    })
                }
                if (err && !(err instanceof NotFoundException)) {
                console.error(err)
                }
            })
    })   
}

export function reset(){
    codeReader.reset()
}