import { getConfig } from "./Utils";
var app = getConfig();
let idb = require("idb")

export function getProductByEan(ean, callback) {
    try{
        var path = "/catalog/product/getByEan?ean=" + ean;
        var url = app.DKW_URL + path;

        var request = new XMLHttpRequest();
        request.onreadystatechange = function() {
            if (request.readyState === XMLHttpRequest.DONE) {
                if (request.status === 200) {
                    try {
                        var results = JSON.parse(request.response);
                        callback(results);
                    } catch(e) {
                        console.log(e)
                        callback({
                            error : true
                        });
                    }
                }
            }
        };
        request.onerror = async function(e) {
                
            const db = await idb.openDB('demokit-food', 1, {
                upgrade(db) {
                    if (!db.objectStoreNames.contains('products')) {
                        const eventsOS = db.createObjectStore('products', {keyPath: 'id'});
                    }
                    
                }
            });
            const values = await db.getAll("products")

            const result = values.filter(product => product.ean == ean)[0]
            if(result){
                callback(result)
            }else{
                callback({
                    error : true
                });
            }

        }
        request.open('GET', url);
        request.send();
    }catch(e){
        console.log(e)
    }
}